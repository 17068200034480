import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js'
import {localStore} from '../utils/main'

let lang={
    zh:"zh_CN",
    en:"en_US",
}

const messages = {}
messages[lang.zh]=require('./zh').default
messages[lang.en]=require('./en').default

const i18n = createI18n({
    locale: localStore.get('locale')||lang.zh,
    messages,
})

localStore.set('locale',i18n.global.locale)
window.locale=i18n.global.locale

export const setLocale=(locale=lang.zh)=>{
    i18n.global.locale=locale
    localStore.set('locale',i18n.global.locale)
    window.location.reload()
}

export const getLocale=()=>{
    return {
        lang,
        locale:i18n.global.locale
    }
}

export default i18n

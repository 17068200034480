let root='/api'
let userRoot='account'
let operation='operation'
let group='group'
let device='device'
let redirect='redirection'
export default{
    user:{
        login:`${root}/${userRoot}/login`,//post

        refreshToken:`${root}/${userRoot}/login/refresh`,//get

        create:`${root}/${userRoot}`,//post
        remove:`${root}/${userRoot}`,//delete
        getByPage:`${root}/${userRoot}/list`,//get

        getImgCode:`${root}/${userRoot}/images/generate`,//get
        verifyImgCode:`${root}/${userRoot}/images/verify`,//post

        findPwd:`${root}/${userRoot}/password/find`,//put

        postEmailCode:`${root}/${userRoot}/email/code`,//post
        verifyEmailCode:`${root}/${userRoot}/email/verify`,//post

        getInfo:(id)=>`${root}/${userRoot}/${id}/detail`,//get
        changeStatus:(id)=>`${root}/${userRoot}/${id}/status`,//put
        updateInfo:(id)=>`${root}/${userRoot}/${id}`,//put
        reset:`${root}/${userRoot}/reset`,//put
        updateSelfPwd:`${root}/${userRoot}/password`,//put
    },
    log:{
        system:`${root}/${operation}/log`,//get
    },
    group:{
        add:`${root}/${group}`,//post
        getTree:`${root}/${group}/tolist`,//get
        update:(id)=>`${root}/${group}/${id}`,//put
        remove:(id)=>`${root}/${group}/${id}`,//delete
    },
    device:{
        import:`${root}/${device}/import/from-excel`,//post
        export:`${root}/${device}/export/to-excel`,//get
        tmp:`${root}/${device}/template`,//get
        toPage:`${root}/${device}/topage`,//get
        remove:`${root}/${device}`,//delete
        changeGroup:`${root}/${device}/group`,//delete
        toPageNotGroup:`${root}/${device}/unassigned/topage`,//get
        update:()=>`${root}/${device}/{id}`,//put
    },
    redirect:{
        toPage:`${root}/${redirect}/topage`,//get
        add:`${root}/${redirect}`,//post
        remove:`${root}/${redirect}`,//delete
        getDetail:`${root}/${redirect}/detail`,//delete
        update:(id)=>`${root}/${redirect}/${id}`,//put
        changeStatus:(id)=>`${root}/${redirect}/${id}/status`,//put

    }
}
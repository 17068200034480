export default {
    reset:'Reset',
    enable:'enable',
    disabled:'disabled',
    edit:'edit',
    del:'delete',
    createTime:'CreateTime',
    action:'Action',
    createOk:'Created successfully',
    ok:'ok',
    cancel:'cancel',
    updateOk:'Edit ok',
    actionOk:'Operation succeeded',
    pwdPlz:'please enter 6-32 digits of numbers or letters, special characters are supported',
    pwdPlz1:'please enter a new password',
    pwdPlz2:'please enter a duplicate password',
    pwdPlz3:'inconsistent passwords',
    pwdPlz4:'The password is 6-32 characters long and supports numbers, letters and special characters',
    pwdPlz5:'It is recommended that passwords contain numbers, letters, and special characters',
    editPwd:'Change Password',
    oldPwd:'old password',
    newPwd:'new password',
    rePwd:'duplicate password',
    editEmail:'Modify mailbox',
    newEmail:'new mailbox',
    editEmailPlz:'Please enter a new mailbox',
    editEmailPlz2:'Please enter the correct email format',
    pwd2:"login password",
    ip:"IP",
    search:"Search",
    date:"Date",
    batchImport:"Batch import",
    batchImportPlz:"Up to 10000 pieces of data can be imported at a time",
    delOk:"Delete Ok",
    users:"User",
    DOMAIN:'DOMAIN',
    GROUP:'GROUP',
    DEVICE:'DEVICE',
    creator:'Creator',
    port:'Port',
    xuanTian:'Optional filling',
    service:'Service',
    set:'Setting',
    safe:'Higher security',
    add: 'add',
    back: 'back',
    forever: 'Forever',
    pages:{
        total:'Total {num}'
    },
    header:{
        name:'Redirect service system',
        version:'Ver. {num}',
        logout:'Logout',
    },
    menu:{
        deviceManger:'Device management',
        deviceList:'Device list',
        redirect:'Redirection policy',
        userManger:'User management',
        userList:'User list',
        personSet:'Personal settings',
        systemLog:'System log',
    },
    login:{
        cloud:'Cloud storage',
        desc:'Distributed deployment management system',
        welcome:'Welcome to login',
        itemFunc:'Functional features',
        plzAcct:'Email',
        plzPwd:'Password',
        plzCode:'Verification Code',
        loginBtn:'Login',
        forget:'Forget password',
        plzAcc1:'Please enter the account number',
        plzPwd1:'Please input a password',
        plzPwd2: 'If it is empty, a random 6-digit password will be generated and sent to the mailbox. Please ensure that the mailbox is available',
        plzCode1:'Please enter the verification code',
        desc1:'Distributed management',
        desc2:'Batch delivery',
        desc3:'Directional service',
        firstLogin:'Please reset your password when logging in for the first time',
    },
    forget:{
        title:'Forgot password',
        step1:'Enter account number',
        step2:'Authentication',
        step3:'Reset password',
        step4:'Finish',
        loginEmail:'login mailbox',
        loginEmailPlz:'please enter login email',
        loginEmailPlz2:'the mailbox format is incorrect',
        code:'verification code ',
        codePlz:'please enter the verification code',
        codeInfo:'the verification code is invalid in {time} seconds',
        codeInfo2:'no mail received ?',
        resend:'resend',
        step4Desc:'you have successfully modified your password, please log in to the platform with your new password',
        relogin:'return Login',
        next:'Next',
    },
    err:{
        404:'NOT FOUND',
        plz:'Sorry, the page you visited does not exist.',
        VALIDATION_FAILED:'Verification code verification failed',
        DEVICE_IS_EXISTED:'Device already exists',
        REDIRECTION_SERVER_OVER_LIMIT: "Configurable redirection has reached the maximum limit",
        REDIRECTION_NOT_FOUND: "No modifiable redirects were found",
        REDIRECTION_OF_DOMAIN_OVER_LIMIT: "Only one global redirect is supported",
        USER_NOT_FOUND: "User not found",
        USERNAME_ALREADY_EXIST: "User already exists, please do not create it again",
        USER_WAS_FORBID: "User disabled",
        USERNAME_NOT_EMPTY: "Login user name cannot be empty",
        INVALID_USERNAME_OR_PASSWORD: "Wrong user name or password",
        PASSWORD_NOT_EMPTY: "Login password cannot be empty",
        USER_INVALID_PASSWORD: "Password error",
        PASSWORD_NOT_MATCH: "Inconsistent passwords",
        ORIGINAL_PASSWORD_IS_INCORRECT: "Original password error",
        RESET_PASSWORD_FAILED: "Failed to reset password",
        USER_TOKEN_IS_EXPIRED: "The token has expired",
        TOKEN_COMPARE_FAILED: "Illegal request token",
        TOKEN_IS_ILLEGAL: "Illegal request token",
        VERIFY_CODE_ERROR: "Login verification code error",
        EMAIL_ALREADY_EXISTS: "Mailbox already exists",
        THE_NUMBER_OF_ERRORS_HAS_REACHED_THE_LIMIT: "The maximum number of login errors has been reached",
        USER_OVER_LIMIT: "The number of users exceeds the maximum",
        USER_IS_EXPIRED: "Account expired",
        loginErr: "Auth failed",
        INTERNAL_SERVER_ERROR: "INTERNAL SERVER ERROR",
        BAD_REQUEST: "BAD REQUEST",
        DEVICE_SN_FORMAT_INCORRECT: "Device SN error",
        FILE_PARSE_ERROR: "File parsing error",
        DEVICES_IN_THE_GROUP: "DEVICES IN THE GROUP",
        SUBGROUPS_EXIST: "Subgroups exist and cannot be deleted",
        PASSWORD_MATCH: "Consistent password",
    },
    user:{
        searchPlz1:'Account \\ belonging user',
        search:'User search',
        status:'User status',
        expTime:'Expiration time',
        list:'User list',
        create:'Create account',
        resetPwd:'Reset password',
        ext:'Expired',
        extNot:'About to expire',
        acct:'Login account',
        type:'User type',
        type1:'Admin',
        type2:'Users',
        attr:'Home user',
        extTime:'Expiration time',
        activeState:'Status',
        pwd:'Password',
        name:'User name',
        plzName:'corporate name',
        acctType:"Account Type",
        enabledPlz:'Are you sure to enable account?',
        disabledPlz:'Are you sure to disable the account?',
        resetPwdPlz:'Are you sure you want to reset the selected account to the initial password (123456)?',
        delRows:'Batch delete',
        delRowsDesc1:'Are you sure to delete the selected account?',
        select:'Please select accounts',
        FORBIDDEN: "FORBIDDEN",
        GROUP_NAME_ALREADY_EXISTS: "GROUP NAME ALREADY EXISTS",
    },
    plz:{
        select:'please select',
        input:'Please enter',
        inputIp:'Please enter IP',
        inputPort:'Please enter Port',
        inputIp1:'Please enter the correct IP address',
        inputPort1:'Please enter the correct port',
        inputUrl:'Please enter the correct network address',
        inputLogin:'Please enter  user name',
        inputPwd:'Please input  password',
        inputLimit1: "Up to 12 characters",
    },
    personal:{
        plz1:'Mailbox can be used for login and password retrieval',
        title:'Basic user information',
        state1:'in use',
        state2:'disabled',
        acctSet:'Account Settings',
        pwdEdit:'Password modification',
    },
    log:{
        title:'System log',
        desc:'Pedagogical operation',
        actTime:'Operation time',
        acct:'system account',
    },
    device:{
        title:'Device list',
        info:'info',
        searchDevice:'Search device',
        searchDevicePlz:'device SN or MAC',
        group:'Device group',
        import:'Device import',
        del:'Delete device',
        export:'Export device',
        exportPlz1:'Confirm to export all devices?',
        exportPlz2:'Confirm to export the current grouped equipment?',
        exportOk:'Export complete!',
        sn:"SN",
        mac:"MAC",
        user:"User",
        groupAs:"Assign to",
        importPlz:"Please upload excel!",
        userAttr:"Home user",
        groupAttr:"grouping",
        delPzl1:"Are you sure to delete the device?",
        delPzl2:"Are you sure to delete the currently selected {num} devices?",
    },
    upload:{
        title:'Click or drag the file here to upload',
        success:'{name} file uploaded successfully.',
        error:'{name} file upload failed.',
        tmpDow:'Download excel template',
        reUp:'Re upload',
        uploadOk:'Import succeeded',
        up:'upload file',
        err1:'file type err',
    },
    group:{
        searchPzl:'Search grouping',
        searchPzl2:'Search user',
        all:'All users',
        show:'Group display',
        noArr:'Ungrouped',
        yesArr:'Grouped',
        add:'New grouping',
        addPlz1:`1. Up to three levels of grouping can be added`,
        name:'Group name',
        pName:'Parent group',
        root:'Top level grouping',
        edit:'Modify grouping',
        err1:'Please select a group',
        del:'Delete group',
        delPlz1:'Are you sure you want to delete the group ({name})',
    },
    redirect:{
        search:'Policy search',
        searchPzl:'Policy name',
        name:'Policy name',
        range:'Scope',
        state:'Policy status',
        disabledPlz:'Are you sure you want to disable the policy?',
        enabledPlz:'Are you sure to enable the policy?',
        list:'Policy list',
        create:'Policy create',
        del:'Delete policy',
        delPlz:'Are you sure you want to delete the selected device',
        enable:'enable',
        disable:'disabled',
        server:'Redirecting services',
        service:'{name} service',
        url:'URL',
        login:'Account',
        pwd:'Password',
        auth:'Auth.',
        device:'Device',
        add:'Add policy',
        edit:'Edit policy',
        rangeForm:'Range',
        rangeFormName:'{name}',
        PROVISIONPlz:'Support file address or folder address',
        upLimit:'Supported extensions:. PEM /. CRT /. Cer /. Der',
        desc:'Policy desc.',
        err2:'Please select device',
        err3:'Please select group',
        err4:'Please fill in at least one redirect service or other service',
        addOk:'Created successfully',
        editOk:'Modified successfully',
    }
}
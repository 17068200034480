<template>
    <div class="flex between header">
        <div class="flex left name" @click="$router.push('/')">
            <img src="/logo/logo1.png" alt="dinstar">
            <span>{{$t('header.name')}}</span>
        </div>
        <div class="flex right">
            <app-locale/>
        </div>
    </div>
</template>

<script>
    import appLocale from "./appLocale";
    export default {
        components:{
            appLocale,
        },
    }
</script>

<style lang="scss" scoped>
.header{
    height: 60px;
    padding: 0 24px;
    background: #001529;

    font-weight: 400;

    &,::v-deep(span){
        color: #FAFBFC;
    }

    .name{
        font-size: 20px;
        cursor: pointer;
        img{
            height: 18px;
            margin-right: 8px;
        }
    }

}
</style>
export default {
    reset: '重置',
    enable: '启用',
    disabled: '禁用',
    edit: '修改',
    del: '删除',
    createTime: '创建时间',
    action: '操作',
    createOk: '创建成功',
    ok: '确定',
    cancel: '取消',
    updateOk: '修改成功',
    actionOk: '操作成功',
    pwdPlz: '请输入6-32位数字或字母，支持特殊字符',
    pwdPlz1: '请输入新密码',
    pwdPlz2: '请输入重复密码',
    pwdPlz3: '密码不一致',
    pwdPlz4: '密码长度6-32个字符，支持数字、字母、特殊字符',
    pwdPlz5: '建议密码包含数字、字母、特殊字符',
    editPwd: '修改密码',
    oldPwd: '旧密码',
    newPwd: '新密码',
    rePwd: '重复密码',
    editEmail: '修改邮箱',
    newEmail: '新邮箱',
    editEmailPlz: '请输入新邮箱',
    editEmailPlz2: '请输入正确的邮箱格式',
    pwd2: "平台登录密码",
    ip: "IP",
    search: "搜索",
    date: "日期",
    batchImport: "批量导入",
    batchImportPlz: "一次最多可导入10000条数据",
    delOk: "删除成功",
    users: "用户",
    DOMAIN: '全域',
    GROUP: '分组',
    DEVICE: '设备',
    creator: '创建人',
    port: '端口',
    xuanTian: '选填',
    service: '服务',
    set: '设置',
    safe: '安全性更高',
    add: '添加',
    back: '返回',
    forever: '永久',
    pages: {
        total: '共 {num} 条'
    },
    header: {
        name: '重定向服务配置',
        version: 'V {num} 版本',
        logout: '退出',
    },
    menu: {
        deviceManger: '设备管理',
        deviceList: '设备列表',
        redirect: '重定向策略',
        userManger: '用户管理',
        userList: '用户列表',
        personSet: '个人设置',
        systemLog: '系统日志',
        redirectAdd: '新增策略',
        redirectEdit: '修改策略',
    },

    login: {
        cloud: '云端存储',
        desc: '分布式部署管理系统',
        welcome: '欢迎登陆',
        itemFunc: '功能特点',
        plzAcct: '邮箱',
        plzPwd: '密码',
        plzCode: '验证码',
        loginBtn: '登录',
        forget: '忘记密码',
        plzAcc1: '请输入账号',
        plzPwd1: '请输入密码',
        plzPwd2: '为空生成随机6位密码并发往邮箱，请确保邮箱可用',
        plzCode1: '请输入验证码',
        desc1: '分布式管理',
        desc2: '批量式出厂',
        desc3: '定向服务',
        firstLogin:'首次登录请重置密码',
    },
    forget: {
        title: '忘记密码',
        step1: '输入账号',
        step2: '验证身份',
        step3: '重置密码',
        step4: '完成',
        loginEmail: '登录邮箱',
        loginEmailPlz: '请输入登录邮箱',
        loginEmailPlz2: '邮箱格式不正确',
        code: '验证码 ',
        codePlz: '请输入验证码',
        codeInfo: '验证码还有 {time} 秒 无效',
        codeInfo2: '没收到邮件?',
        resend: '重新发送',
        step4Desc: '您已经成功修改密码，请使用新密码登录平台',
        relogin: '返回登录',
        next: '下一步',
    },
    err: {
        404: '未找到',
        plz404: '你访问的页面不存在',
        VALIDATION_FAILED: '验证码校验失败',
        DEVICE_IS_EXISTED: '设备已存在',
        REDIRECTION_SERVER_OVER_LIMIT: "可配置的重定向已达到最大限制",
        REDIRECTION_NOT_FOUND: "未找到可修改的重定向",
        REDIRECTION_OF_DOMAIN_OVER_LIMIT: "全域重定向仅支持一条",
        USER_NOT_FOUND: "用户未找到",
        USERNAME_ALREADY_EXIST: "用户已存在，请不要重复创建",
        USER_WAS_FORBID: "用户被禁用",
        USERNAME_NOT_EMPTY: "登录用户名不能为空",
        INVALID_USERNAME_OR_PASSWORD: "用户名或密码错误",
        PASSWORD_NOT_EMPTY: "登陆密码不能为空",
        USER_INVALID_PASSWORD: "密码错误",
        PASSWORD_NOT_MATCH: "前后密码不一致",
        ORIGINAL_PASSWORD_IS_INCORRECT: "原始密码错误",
        RESET_PASSWORD_FAILED: "重置密码失败",
        USER_TOKEN_IS_EXPIRED: "令牌已过期",
        TOKEN_COMPARE_FAILED: "请求令牌非法",
        TOKEN_IS_ILLEGAL: "请求令牌非法",
        VERIFY_CODE_ERROR: "登陆验证码错误",
        EMAIL_ALREADY_EXISTS: "邮箱已存在",
        THE_NUMBER_OF_ERRORS_HAS_REACHED_THE_LIMIT: "登录错误次数已达上限",
        USER_OVER_LIMIT: "用户数量超过上限",
        USER_IS_EXPIRED: "账号过期",
        loginErr: "身份过期",
        INTERNAL_SERVER_ERROR: "服务不可用",
        BAD_REQUEST: "请求参数错误",
        FORBIDDEN: "无权限",
        GROUP_NAME_ALREADY_EXISTS: "分组名称已存在",
        DEVICE_SN_FORMAT_INCORRECT: "设备序列号格式不正确",
        FILE_PARSE_ERROR: "文件解析错误",
        DEVICES_IN_THE_GROUP: "分组中存在设备",
        SUBGROUPS_EXIST: "存在子分组无法删除",
        PASSWORD_MATCH: "前后密码一致",
    },
    user: {
        searchPlz1: '账号\\归属用户',
        search: '用户搜索',
        status: '用户状态',
        expTime: '到期时间',
        list: '用户列表',
        create: '创建账号',
        resetPwd: '重置密码',
        ext: '已过期',
        extNot: '即将过期',
        acct: '登录账号',
        type: '用户类型',
        type1: '管理员',
        type2: '普通用户',
        attr: '归属用户',
        extTime: '过期时间',
        activeState: '状态',
        pwd: '密码',
        name: '用户姓名',
        plzName: '公司名称',
        acctType: '账号类型',
        enabledPlz: '确定启用账号？',
        disabledPlz: '确定禁用账号？',
        resetPwdPlz: '确认要将所选账号重置为初始密码（123456）？',
        delRows:'批量删除',
        delRowsDesc1:'确定删除选中的账号？',
        select:'请选择账号',
    },
    plz: {
        select: '请选择',
        input: '请输入',
        inputIp: '请输入IP',
        inputPort: '请输入端口',
        inputIp1: '请输入正确的IP',
        inputPort1: '请输入正确的端口',
        inputUrl: '请输入正确的网络地址',
        inputLogin: '请输入用户名',
        inputPwd: '请输入密码',
        inputLimit1: "最多12位字符",
    },
    personal: {
        plz1: '邮箱可用于登录、找回密码',
        title: '用户基本信息',
        state1: '使用中',
        state2: '已禁用',
        acctSet: '账号设置',
        pwdEdit: '密码修改',
    },
    log: {
        title: '系统日志',
        desc: '操作描述',
        actTime: '操作时间',
        acct: '系统账号',
    },
    device: {
        title: '设备列表',
        info: '详情',
        searchDevice: '搜索设备',
        searchDevicePlz: '设备SN 、设备MAC',
        group: '设备分组',
        import: '设备导入',
        del: '删除设备',
        export: '导出设备',
        exportPlz1: '确认导出全部设备？',
        exportPlz2: '确认导出当前分组设备？',
        exportOk: '导出完成!',
        sn: "设备SN",
        mac: "设备MAC",
        user: "用户",
        groupAs: "分配至",
        importPlz: "请上传Excel!",
        userAttr: "归属用户",
        groupAttr: "设备分组",
        delPzl1: "确定删除设备？",
        delPzl2: "确认删除当前选中{num}台设备？",
    },
    upload: {
        title: '点击或将文件拖拽到这里上传',
        success: '{name} 上传成功',
        error: '{name} 上传失败',
        tmpDow: '下载excel模版',
        reUp: '重新上传',
        uploadOk: '导入成功',
        up: '上传文件',
        err1: '文件类型错误',
    },
    group: {
        searchPzl: '搜索分组',
        searchPzl2: '搜索用户',
        all: '全部用户',
        show: '分组显示',
        noArr: '未分组',
        yesArr: '已分组',
        add: '新增分组',
        addPlz1: `1、最多可添加三级分组`,
        name: '分组名称',
        pName: '父级分组',
        root: '顶级分组',
        edit: '修改分组',
        err1: '请选择分组',
        del: '删除分组',
        delPlz1: '确定要删除分组（{name}）',
    },
    redirect: {
        search: '策略搜索',
        searchPzl: '策略名称',
        name: '策略名称',
        range: '适用范围',
        state: '策略状态',
        disabledPlz: '确定禁用策略？',
        enabledPlz: '确定启用策略？',
        list: '策略列表',
        create: '创建策略',
        del: '删除策略',
        delPlz: '确定要删除已选中的设备',
        enable: '使用中',
        disable: '已禁用',
        server: '重定向服务',
        service: '{name}服务',
        url: 'URL地址',
        login: '登录账号',
        pwd: '密码',
        auth: '信用证书',
        device: '适用设备',
        add: '新增策略',
        edit: '修改策略',
        rangeForm: '指定范围',
        rangeFormName: '指定{name}',
        PROVISIONPlz: '支持文件地址或文件夹地址',
        upLimit: '支持扩展名：.pem/.crt/.cer/.der',
        desc: '策略描述',
        err2: '请选择设备',
        err3: '请选择分组',
        err4: '请填写至少一个重定向服务或其他服务',
        addOk: '创建成功',
        editOk: '修改成功',
    }
}
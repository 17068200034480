import api from "./api";
import fetch from "../utils/fetch";
import moment from "moment";
import {localStore} from "../utils/main";
import constName from "../utils/constName";
export async function toPage(data) {
    if(data.beginTime){
        data.beginTime=moment(data.beginTime).startOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    if(data.endTime){
        data.endTime=moment(data.endTime).endOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    return await fetch({
        api: api.device.toPage,
        method:'GET',
        params:{
            groupId:data.groupId,
            ifAll:data.ifAll,//true查所有设备，无关分组
            search:data.search,
            uid:data.uid,

            beginTime:data.beginTime,
            endTime:data.endTime,

            cursor:data.cursor,
            limit:data.limit,
        }
    })
}

export async function toPageNotGroup(data) {
    if(data.beginTime){
        data.beginTime=moment(data.beginTime).startOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    if(data.endTime){
        data.endTime=moment(data.endTime).endOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    return await fetch({
        api: api.device.toPageNotGroup,
        method:'GET',
        params:{
            uid:data.uid,
            search:data.search,
            beginTime:data.beginTime,
            endTime:data.endTime,

            cursor:data.cursor,
            limit:data.limit,
        }
    })
}

export async function add(data) {
    return await fetch({
        api: api.group.add,
        method:'POST',
        params:{
            "groupName": data.groupName,
            "parentId": data.parentId,
            "uid": data.uid,
        }
    })
}

export async function update(data) {
    return await fetch({
        api: api.group.update(data.id),
        method:'PUT',
        params:{
            "groupName": data.groupName,
        }
    })
}

export async function remove(data) {
    return await fetch({
        api: api.group.remove(data.id),
        method:'DELETE',
    })
}

export async function importDevice(data) {
    let req={
        file:data.file,
        groupId:data.groupId,
        uid:data.uid,
    }
    if(!req.groupId){
        delete req.groupId
    }
    return await fetch({
        api: api.device.import,
        method:'POST',
        type:'formData',
        data:req
    })
}

export async function exportDeviceOpen(groupId,uid) {
    let loginInfo=localStore.get(constName.LoginInfo)
    let token=loginInfo.tokenType+' '+loginInfo.token
    let url=`${api.device.export}?token=${token}`
    if(groupId){
        url+=`&groupId=${groupId}`
    }
    if(uid){
        url+=`&uid=${uid}`
    }
    window.open(url)
}

export async function changeGroup(data) {
    return await fetch({
        api: api.device.changeGroup,
        method:'PUT',
        type:'json',
        data:{
            deviceIds:data.deviceIds,
            groupId:data.groupId,
        }
    })
}

export async function deviceRemove(data) {
    return await fetch({
        api: api.device.remove,
        method:'DELETE',
        type:'json',
        data:data.ids
    })
}


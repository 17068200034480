import i18n from "../locale/main";
import {getInfo,getList} from "../service/user";
import {localStore} from "../utils/main";
import constName from "../utils/constName";
import moment from "moment";
let t = i18n.global.t
let userInfo=localStore.get(constName.UserInfo)
const state= {
    userInfo:userInfo||{},
    current:1,
    size:10,
    total:0,
    sizeAll:['1','10','20','30'],
    searchForm:{},
    data:userInfo?.ifAdmin?[userInfo]:userInfo?[userInfo]:[]
}
const getters={
    /*vuexStateBFindOne: state => (search)=>{
        return state.b.filter(todo => todo.indexOf(search)>-1)
    }*/
}
const actions={
    async search ({commit,state},val) {
        let req={
            status:val.searchForm?.status,
            search:val.searchForm?.search,
            beginTime:(val.searchForm?.date?val.searchForm?.date[0]:null),
            endTime:(val.searchForm?.date?val.searchForm?.date[1]:null),
            cursor:val.current,
            limit:val.size||state.size,
        }
        if(!val.search){
            req={
                status:state.searchForm?.status,
                search:state.searchForm?.search,
                beginTime:(state.searchForm?.date?state.searchForm?.date[0]:null),
                endTime:(state.searchForm?.date?state.searchForm?.date[1]:null),
                cursor:val.current,
                limit:val.size||state.size,
            }
        }
        if(userInfo?.ifAdmin){
            let rs=await getList(req)
            if(val.searchForm){
                commit('changSearchForm',val.searchForm)
            }
            commit('changPageSize',{current: val.current,size:val.size||state.size,total:rs.data.result.total})
            commit('changeData',rs.data.result.records)
        }
        // console.log('fetch:',state.searchForm,val)
    },
    async setInfo({commit},val){
        let rs = await getInfo(val.id)
        commit('setInfo',rs.data.result)
    },
    clear({commit}){
        commit('clear')
    },
    init({commit}){
        commit('init')
    }
}
const mutations={
    clear(state){
        state.userInfo={}
        state.data=[]
        localStore.remove(constName.UserInfo)
    },
    init(state){
        userInfo=localStore.get(constName.UserInfo)
        state.data=userInfo?.ifAdmin?[userInfo]:userInfo?[userInfo]:[]
    },
    setInfo(state,val){

        val.acct=val.loginName
        val.type=val.ifAdmin?t('user.type1'):t('user.type2')
        val.attr=val.username
        val.createTime=val.createAt
        val.extTime=val.expireAt
        val.actionState=val.status
        val.ext=moment(val.expireAt).diff(moment(),'seconds')<0?true:false
        val.extOver=moment(val.expireAt).diff(moment(),'seconds')<30&&val.ext===false?true:false

        state.userInfo=val
        localStore.set(constName.UserInfo, val)
    },
    changPageSize(state,{current,size,total}){

        state.current=current
        state.size=size
        state.total=total
    },
    changSearchForm(state,val){

        state.searchForm={...val}

    },
    changeData(state,data){

        let newData=data.map(r=>{
            r.acct=r.loginName
            r.type=r.ifAdmin?t('user.type1'):t('user.type2')
            r.attr=r.username
            r.createTime=r.createAt
            r.extTime=r.expireAt
            r.actionState=r.status
            r.ext=moment(r.expireAt).diff(moment(),'seconds')<0?true:false
            r.extOver=moment(r.expireAt).diff(moment(),'seconds')<30&&r.ext===false?true:false
            return r
        })

        state.data=newData
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import router from '../router'
import {localStore} from '../utils/main'
const localName='appTabs'
const state= {
    arr:localStore.get(localName)||[]
}
const getters={
    /*vuexStateBFindOne: state => (search)=>{
        return state.b.filter(todo => todo.indexOf(search)>-1)
    }*/
}
const actions={
    addTabs({commit},val){
        commit("addTabs", val)
    },
    reduceTabs({commit},val){
        commit("reduceTabs", val)
    },
    removeTabs({commit},val){
        commit("removeTabs", val)
    },
    changeActive({commit},val){
        commit("changeActive", val)
    },
    clear({commit},val){
        localStore.remove(localName)
        commit("clear", val)
    },

}
const mutations={
    addTabs(state,val){
        let item={
            key:state.arr.length,//index
            name:`menu.${val.name}`,//路由Tab名称 menu.name
            routeName:val.routeName,//路由名称
            params:val.params,
            query:val.query,
            active:true//当前路由
        }
        let arr=[...state.arr].map(r=>{
            r.active=false
            return r
        })
        let i=arr.find(r=>r.routeName===item.routeName)
        if(i){
            i.active=true
            state.arr=[...arr]
            return;
        }
        if(arr.length>8){
            arr.shift()
        }
        state.arr=[...arr,item]
        console.log(arr,item)
        localStore.set(localName,state.arr)
    },
    changeActive(state,val){
        let arr=[...state.arr].map(r=>{
            r.active=false
            if(r.key===val.key){
                r.active=true
            }
            return r
        })
        state.arr=[...arr]
        localStore.set(localName,state.arr)
    },
    reduceTabs(state,val){
        let arr=[...state.arr]

        arr.map((r,k)=>{
            if(val.key===k){
                arr.splice(k,1)
            }
        })
        if(arr.length>0&&!arr.find(r=>r.active)){
            let item=arr[arr.length-1]
            item.active=true
            router.push({name:item.routeName})
        }
        state.arr=[...arr]
        localStore.set(localName,state.arr)
    },
    removeTabs(state,{routeName}){
        let arr=[...state.arr]
        arr.map((r,k)=>{
            if(routeName===r.routeName){
                arr.splice(k,1)
            }
        })
        state.arr=[...arr]
        localStore.set(localName,state.arr)
    },
    clear(state,){
        state.arr=[]
    }

}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
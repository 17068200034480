import {toPage} from "../service/redirect";

const state = {
    current: 1,
    size: 10,
    total: 0,
    sizeAll: ['10', '20', '30'],
    searchForm: {},
    data: [
        /*{
            name: 'cc1',
            range: t('DEVICE'),
            user: 'jack',
            createTime: '2021-12-31 23:59:59',
            creator: 'suo qiao',
            strategies: [
                {
                    aimServer: 'NMS',
                    ip: '172.28.1.100',
                    port: 2006,
                }
            ],
            provision: {
                url: 'https://2x.antdv.com/components/button-cn',
                login: 'root',
                pwd:'123456',
                auth: 'https://2x.antdv.com/components/button-cn',
                authName: 'button-cn',
            },
            devicesList:[
                {deviceSn:'1111-1111-1111-1111',mac:'ab:cd:ee:fg:ee'}
            ],
            desc:'123132',
            //scope:'DOMAIN',
            state: true,
            key: 1,
        },*/

    ]
}
const getters = {
    /*vuexStateBFindOne: state => (search)=>{
        return state.b.filter(todo => todo.indexOf(search)>-1)
    }*/
}
const actions = {
    async search({commit,state}, val) {
        let req={
            search:val.searchForm?.search,
            uid:val.searchForm?.users,
            status:val.searchForm?.state,
            scope:val.searchForm?.range,

            cursor:val.current,
            limit:val.size||state.size,
        }
        if(!val.search){
            req={
                search:state.searchForm?.search,
                uid:state.searchForm?.users,
                status:state.searchForm?.state,
                scope:state.searchForm?.range,

                cursor:val.current,
                limit:val.size||state.size,
            }
        }
        let rs=await toPage(req)

        if (val.searchForm) {
            commit('changSearchForm', val.searchForm)
        }
        commit('changPageSize',{current: val.current,size:val.size||state.size,total:rs.data.result.total})
        commit('changeData',rs.data.result.records)
        // console.log('fetch:',state.searchForm,val)
    },
}
const mutations = {
    changPageSize(state,{current,size,total}){
        state.current=current
        state.size=size
        state.total=total
    },
    changSearchForm(state,val){
        if(val.clear){
            state.searchForm={}
        }
        state.searchForm={...val}

    },
    changeData(state,data){
        let newData=data.map(r=>{
            r.desc=r.description
            r.state=r.ifEnable
            r.createTime=r.createAt
            r.user=r.username
            if(!r.scopeName){
                r.scopeName=''
            }
            return r
        })
        state.data=newData
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import api from "./api";
import fetch from "../utils/fetch";
export async function getTree(data) {
    return await fetch({
        api: api.group.getTree,
        method:'GET',
        params:{
            uid:data?.uid,
        }
    })
}

export async function add(data) {
    return await fetch({
        api: api.group.add,
        method:'POST',
        type:'json',
        data:{
            "groupName": data.groupName,
            "parentId": data.parentId,
            "uid": data.uid,
        }
    })
}

export async function update(data) {
    return await fetch({
        api: api.group.update(data.id),
        method:'PUT',
        type:'json',
        data:{
            "groupName": data.groupName,
        }
    })
}

export async function remove(data) {
    return await fetch({
        api: api.group.remove(data.id),
        method:'DELETE',
    })
}
<template>
    <div class="over-page">
        <app-header v-if="$route.path!=='/login'"/>
        <router-view/>
    </div>
</template>

<script>
    import appHeader from "../components/appHeader";

    export default {
        name:'Logout',
        components:{
            appHeader,
        },
    }
</script>

<style scoped>

</style>
import api from "./api";
import fetch from "../utils/fetch";
import moment from "moment";
export async function systemLog(data) {
    if(data.beginTime){
        data.beginTime=moment(data.beginTime).startOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    if(data.endTime){
        data.endTime=moment(data.endTime).endOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    return await fetch({
        api: api.log.system,
        method:'GET',
        params:{
            uid:data.uid,
            operation:data.operation,

            beginTime:data.beginTime,
            endTime:data.endTime,

            cursor:data.cursor,
            limit:data.limit,
        }
    })
}
import axios from "axios";
import api from '../service/api'
import store from '../store/index'
import {localStore} from './main'
import constName from './constName'
import moment from "moment";
import {Modal} from "ant-design-vue";
import i18n from "../locale/main";
export default async function () {
    let loginInfo=localStore.get(constName.LoginInfo)
    let auth=localStore.get(constName.auth)
    if(!loginInfo||!loginInfo.getDate||!auth){
        return Promise.reject('noAuth')
    }
    let startTime=moment()
    let endTime=moment(loginInfo.getDate).add(loginInfo.expire,'seconds')
    let time=endTime.diff(startTime,'seconds')
    if(time<1*60*60){
        try {
            let rs = await axios.get(api.user.refreshToken,{params:{token:loginInfo.tokenType+' '+loginInfo.token}})
            await store.dispatch('auth/update',{auth:true,authInfo: {...rs.data.result,getDate:moment().format('yyyy-MM-DD HH:mm:ss')}})
        }catch (error) {
            console.log('changeToken:',error)
            let err=error.response.data.error
            if(['USER_TOKEN_IS_EXPIRED','TOKEN_COMPARE_FAILED','TOKEN_IS_ILLEGAL'].indexOf(err)>-1){
                Modal.warning({
                    title: i18n.global.t('err.loginErr'),
                    content: i18n.global.t("err."+err),
                    onOk(){
                        localStore.clear()
                        window.location.href='/'
                    }
                });
            }
        }


    }
}
import i18n from "../locale/main";
import {systemLog} from "../service/log";

let t = i18n.global.t
const state= {
    current:1,
    size:10,
    total:0,
    sizeAll:['10','20','30'],
    searchForm:{},
    data:[]
}
const getters={
    /*vuexStateBFindOne: state => (search)=>{
        return state.b.filter(todo => todo.indexOf(search)>-1)
    }*/
}
const actions={
    async search ({commit},val) {
        let req={
            uid:val.searchForm?.uid,
            operation:val.searchForm?.search,
            beginTime:val.searchForm?.date[0],
            endTime:val.searchForm?.date[1],
            cursor:val.current,
            limit:val.size||state.size,
        }
        if(!val.search){
            req={
                uid:val.searchForm?.uid,
                operation:state.searchForm?.search,
                beginTime:(state.searchForm?.date?state.searchForm?.date[0]:null),
                endTime:(state.searchForm?.date?state.searchForm?.date[1]:null),
                cursor:val.current,
                limit:val.size||state.size,
            }
        }
        let rs=await systemLog(req)

        if(val.searchForm){
            commit('changSearchForm',val.searchForm)
        }
        commit('changPageSize',{current: rs.data.result.current,size:rs.data.result.size,total:rs.data.result.total})
        commit('changeData',rs.data.result.records)
    },
    clear ({commit}) {
        commit('clear')
    }
}
const mutations={
    clear(state) {
        state.current=1
        state.size=10
        state.total=0
        state.searchForm={}
        state.data=[]
    },
    changPageSize(state,{current,size,total}){
        console.log({current,size,total})
        state.current=current
        state.size=size
        state.total=total
    },
    changSearchForm(state,val){
        if(val.clear){
            state.searchForm={}
        }
        state.searchForm={...val}

    },
    changeData(state,data){

        let newData=data.map(r=>{
                r.acct=r.loginName,
                r.type=r.ifAdmin?t('user.type1'):t('user.type2')
                r.attr=r.username
                r.desc=r.operation
                r.actTime=r.createAt
            return r
        })
        state.data=newData
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
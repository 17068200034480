import {toPage, toPageNotGroup} from '../service/device'
import {getTree} from "../service/group";
import {getInfo} from "../service/user";
const loopInitYesArr=(arr=[])=>{
    let arr1=[]
    arr.map(r=>{
        if(r.id!=-1){
            r.rootTag=0
            arr1.push(r)
        }
    })

    const loop=(arr=[])=>{
        for(let item of arr){
            item.title=item.groupName
            item.key=item.id
            item.value=item.id
            item.pKey=item.parentId===0?'':item.parentId
            if(item.children&&item.children.length>0){
                item.children.map(r=>{
                    r.rootTag=item.rootTag+1
                    return r
                })
                loop(item.children)
            }
        }
    }
    loop(arr1)
    return arr1
}

const state = {
    current: 1,
    size: 10,
    total: 0,
    sizeAll: ['10', '20', '30'],
    searchForm: {},
    data: [
        /* {
             sn:'DE10-7611-0130-0083',
             mac:'14:20:5e:0f:83:38',
             user:'xxx',
             group:'aaa1',
         },*/
    ],
    groupId: null,
    uid: null,
    ifGroup: false,
    yesGroup: [],
    choiceGroupArr: [],
    choiceUserId: null,
    choiceUserInfo:null,
}
const getters = {
    /*vuexStateBFindOne: state => (search)=>{
        return state.b.filter(todo => todo.indexOf(search)>-1)
    }*/
}
const actions = {
    async search({commit, state}, val) {
        let req = {
            search: val.searchForm?.search,
            groupId: val.groupId || state.groupId,
            uid: val.uid || state.uid,
            beginTime: (val.searchForm?.date ? val.searchForm?.date[0] : null),
            endTime: (val.searchForm?.date ? val.searchForm?.date[1] : null),
            cursor: val.current,
            limit: val.size || state.size,
        }
        if (!val.search) {
            req = {
                search: state.searchForm?.search,
                groupId: val.groupId || state.groupId,
                uid: val.uid || state.uid,
                beginTime: (state.searchForm?.date ? state.searchForm?.date[0] : null),
                endTime: (state.searchForm?.date ? state.searchForm?.date[1] : null),
                cursor: val.current,
                limit: val.size || state.size,
            }
        }
        let rs = await toPage(req)
        if (val.searchForm) {
            commit('changSearchForm', val.searchForm)
        }
        commit('changPageSize', {current: val.current, size: val.size || state.size, total: rs.data.result.total})
        commit('saveUidGroupId', {groupId: req.groupId, uid: req.uid})
        commit('changeData', rs.data.result.records)
        // console.log('fetch:',state.searchForm,val)
    },
    async searchNotGroup({commit, state}, val) {
        let req = {
            search: val.searchForm?.search,
            uid: val.uid,
            beginTime: (val.searchForm?.date ? val.searchForm?.date[0] : null),
            endTime: (val.searchForm?.date ? val.searchForm?.date[1] : null),
            cursor: val.current,
            limit: val.size || state.size,
        }
        if (!val.search) {
            req = {
                search: state.searchForm?.search,
                uid: val.uid || state.uid,
                beginTime: (state.searchForm?.date ? state.searchForm?.date[0] : null),
                endTime: (state.searchForm?.date ? state.searchForm?.date[1] : null),
                cursor: val.current,
                limit: val.size || state.size,
            }
        }
        let rs = await toPageNotGroup(req)
        if (val.searchForm) {
            commit('changSearchForm', val.searchForm)
        }
        commit('changPageSize', {current: val.current, size: val.size || state.size, total: rs.data.result.total})
        commit('saveUidGroupId', {groupId: null, uid: req.uid})
        commit('changeData', rs.data.result.records)
        // console.log('fetch:',state.searchForm,val)
    },
    setIfGroup({commit}, val) {
        commit('setIfGroup', val)
    },
    async hasGroup({commit,state}) {
        let rs=await getTree({uid:state.choiceUserId})
        commit('hasGroup', rs.data.result)
    },
    saveUid({commit}, val) {
        commit('saveUid', val)
    },
    async saveUidGroupId({commit}, val) {
        if(val.uid){
            let rs=await  getInfo(val.uid)
            if(rs.data.result){
                commit('saveChoiceUserInfo', rs.data.result)
            }else{
                commit('saveChoiceUserInfo', null)
            }
        }
        commit('saveUidGroupId', val)
    },
    saveChoiceUserId({commit}, val) {
        commit('saveChoiceUserId', val)
    },
    async changeChoiceGroupArr({commit}, val) {
        let rs=await getTree({uid:val.id})
        commit('changeChoiceGroupArr', rs.data.result)
    },
    clear({commit}, val) {
        commit('clear', val)
    },
}
const mutations = {
    changPageSize(state, {current, size, total}) {
        state.current = current
        state.size = size
        state.total = total
    },
    changSearchForm(state, val) {
        state.searchForm = {...val}
    },
    setIfGroup(state, val) {
        state.ifGroup = val
    },
    saveUidGroupId(state, {groupId, uid}) {
        state.groupId = groupId
        state.uid = uid
    },
    saveUid(state, {uid}) {
        state.uid = uid
    },
    saveChoiceUserId(state, {uid}) {
        state.choiceUserId = uid
    },
    hasGroup(state, val) {

        state.yesGroup = loopInitYesArr(val)
    },
    changeChoiceGroupArr(state, val) {

        state.choiceGroupArr = loopInitYesArr(val)
    },
    changeData(state, data) {
        let newData = data.map(r => {
            r.createTime = r.createAt
            r.sn = r.deviceSn
            r.user = r.userName
            r.group = r.groupName

            return r
        })
        state.data = newData
    },
    saveChoiceUserInfo(state,data){
        state.choiceUserInfo=data
    },
    clear(state){
        state.searchForm={}
        state.data=[]
        state.groupId=null
        state.uid=null
        state.ifGroup=false
        state.yesGroup=[]
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
export default {
    LoginInfo:"LoginInfo",
    UserInfo:"UserInfo",
    auth:"auth",
    route:{
        deviceList:"deviceList",
        redirect:"redirect",
        redirectAdd:"redirect-add",
        redirectEdit:"redirect-edit",
        userList:"userList",
        personSet:"userPersonSet",
        systemLog:"systemLog",
        forget:"forget",
        login:"login",
    }
}
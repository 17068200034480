import api from "./api";
import fetch from "../utils/fetch";
export async function toPage(data) {
    return await fetch({
        api: api.redirect.toPage,
        method:'GET',
        params:{
            name:data.search,
            uid:data.uid,
            ifEnable:data.status,
            scope:data.scope,

            cursor:data.cursor,
            limit:data.limit,
        }
    })
}


export async function add(data) {
    return await fetch({
        api: api.redirect.add,
        method:'POST',
        type:'json',
        data:{
            "description": data.desc,
            "ifEnable": data.status,
            "password": data.pwd,
            "scope": data.scope,
            "scopeValues": data.scopeValue,
            "name": data.name,
            "strategies": [
                ...data.strategies
                /*{
                    "aim": "",
                    "ip": "",
                    "port": 0
                }*/
            ],
            "uid": data.uid,
            "url": data.url,
        }
    })
}

export async function update(data) {
    return await fetch({
        api: api.redirect.update(data.id),
        method:'PUT',
        type:'json',
        data:{
            "description": data.desc,
            "ifEnable": data.status,
            "password": data.pwd,
            "scope": data.scope,
            "scopeValues": data.scopeValue,
            "strategies": [
                ...data.strategies
                /*{
                    "aimServer": "",
                    "ip": "",
                    "port": 0
                }*/
            ],
            "uid": data.uid,
            "url": data.url,
            "username": data.username
        }
    })
}

export async function changeStatus(data) {
    return await fetch({
        api: api.redirect.changeStatus(data.id),
        method:'PUT',
        type:'json',
        data:data.status,
        header:{
            "Content-Type": "application/json"
        }
    })
}

export async function remove(data) {
    return await fetch({
        api: api.redirect.remove,
        method:'DELETE',
        type:'json',
        data:data.ids
    })
}

export async function getDetail(data) {
    return await fetch({
        api: api.redirect.getDetail,
        method:'GET',
        params:{
            rid:data.id
        }
    })
}



import {createRouter, createWebHashHistory} from 'vue-router'
import store from '../store'
import routes from "./routes";


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async(to, from, next) => {
    if(to.meta.auth){
        let status=store.getters['auth/check']
        store.dispatch('appTabs/addTabs',{routeName:to.name,name:to.meta.menu,params:to.params,query:to.query})
        if(await status()&&!store.state.user.userInfo.ifFirstLogin){
            next()

        }else{
            if(to.path!=='/login'){
                next('/login')
            }else{
                next()
            }
        }
    }else{
        next()
    }

})

export default router

import fetch from '../utils/fetch'
import api from './api'
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import moment from "moment";
export function getImgCode() {
    let UUID = Math.random().toString().slice(2, 6)
    return {
        UUID,
        img: api.user.getImgCode + `?UUID=${UUID}`
    }
}

export async function verifyImgCode(data) {
    return await fetch({
        auth: false,
        api: api.user.verifyImgCode,
        method:'POST',
        data: {
            code:data.code,
            UUID:data.UUID,
        }
    })
}

export async function login(data) {
    return await fetch({
        auth: false,
        api: api.user.login,
        method:'POST',
        data: {
            account:data.acct,
            password: Base64.stringify(Utf8.parse(data.pwd))
        }
    })
}
export async function getInfo(id) {
    return await fetch({
        api: api.user.getInfo(id),
        method:'GET',
    })
}

export async function postEmailCode(data) {
    return await fetch({
        auth: false,
        api: api.user.postEmailCode,
        method:'POST',
        data: {
            receiver:data.acct,
        }
    })
}
export async function verifyEmailCode(data) {
    return await fetch({
        auth: false,
        api: api.user.verifyEmailCode,
        method:'POST',
        data: {
            email:data.acct,
            code:data.code,
        }
    })
}

export async function findPwd(data) {
    return await fetch({
        auth: false,
        api: api.user.findPwd,
        method:'PUT',
        type:'json',
        data: {
            "cryptographic": 0,
            "password": Base64.stringify(Utf8.parse(data.pwd)),
            "sign": data.sign,
            "uid": data.uid,
            "uuid": data.uuid
        }
    })
}

export async function getList(data) {
    if(data.beginTime){
        data.beginTime=moment(data.beginTime).startOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    if(data.endTime){
        data.endTime=moment(data.endTime).endOf('days').format('yyyy-MM-DD HH:mm:ss')
    }
    return await fetch({
        api: api.user.getByPage,
        method:'GET',
        params:{
            name:data.search,
            status:data.status,
            beginTime:data.beginTime,
            endTime:data.endTime,
            cursor:data.cursor,
            limit:data.limit,
        }
    })
}

export async function updateInfo(data) {
    let req= {
        "expireAt": moment(data.extTime).endOf('days').format('yyyy-MM-DD HH:mm:ss'),
        "password":Base64.stringify(Utf8.parse(data.pwd)),
        "loginName":data.acct,
    }
    if(!req.expireAt){
        delete req.expireAt
    }
    if(!req.password){
        delete req.password
    }
    if(!req.loginName){
        delete req.loginName
    }
    return await fetch({
        api: api.user.updateInfo(data.id),
        method:'PUT',
        type:'json',
        data:req
    })
}

export async function updateStatus(data) {
    return await fetch({
        api: api.user.changeStatus(data.id),
        method:'PUT',
    })
}
export async function reset(data) {
    return await fetch({
        api: api.user.reset,
        method:'PUT',
        type:'json',
        data:data.ids
    })
}
export async function remove(data) {
    return await fetch({
        api: api.user.remove,
        method:'DELETE',
        type:'json',
        data:data.ids
    })
}

export async function create(data) {
    return await fetch({
        api: api.user.create,
        method:'POST',
        type:'json',
        data:{
            "expireAt":  moment(data.extTime).endOf('days').format('yyyy-MM-DD HH:mm:ss'),
            "ifAdmin": data.userType==='admin',
            "loginName": data.acct,
            "password": Base64.stringify(Utf8.parse(data.pwd)),
            "username": data.name,
            "status": data.activeState,
        }
    })
}

export async function updateSelfPwd(data) {
    return await fetch({
        api: api.user.updateSelfPwd,
        method:'PUT',
        type:'json',
        data:{
            "cryptographic": 0,
            "originalPassword": Base64.stringify(Utf8.parse(data.oldPwd)),
            "password":Base64.stringify(Utf8.parse(data.newPwd)),
        }
    })
}

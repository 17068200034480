import {localStore} from '../utils/main'
import constName from '../utils/constName'
import {login} from "../service/user";
import moment from "moment";
import checkToken from '../utils/checkToken'
const state= {
    auth: localStore.get(constName.auth)||false,
    authInfo:localStore.get(constName.LoginInfo)||{},
}
const getters={
    check:state =>async()=>{
        try {
            await checkToken()
            return state.auth
        }catch (e) {
            if(e==='noAuth'){
                return false
            }
        }
    }
}
const actions={
    async init({dispatch,commit}) {
        let auth=localStore.get(constName.auth)
        let authInfo=localStore.get(constName.LoginInfo)
        if(auth&&authInfo){
            commit("update", {auth,info:authInfo})
        }else{
            dispatch("clear")
        }
    },
   async login({dispatch},val){
       let rs = await login(val)
       await dispatch('update', {auth: true, authInfo: {...rs.data.result,getDate:moment().format('yyyy-MM-DD HH:mm:ss')}})
       await dispatch('user/setInfo',{id:rs.data.result.id},{root:true})
   },
   async update ({commit},val) {
        localStore.set(constName.auth,val.auth)
        localStore.set(constName.LoginInfo,val.authInfo)
        commit("update", val)
    },
    clear({commit}){
        commit('clear')
    }
}
const mutations={
    clear(){
        state.auth=false
        state.authInfo= {}
        localStore.remove(constName.auth)
        localStore.remove(constName.LoginInfo)
    },

    update (state, {auth,info}) {
        state.auth=auth
        state.authInfo=info
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
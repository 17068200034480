<template>
    <a-config-provider :locale="language">
        <before-login v-if="$route.meta.layout==='Logout'"/>
        <router-view v-else/>
    </a-config-provider>
</template>
<script>
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import enUS from 'ant-design-vue/es/locale/en_US';
    import {getLocale} from './locale/main'
    import {onMounted, ref} from 'vue'
    import {useStore} from 'vuex'
    import BeforeLogin from "./layout/BeforeLogin";
    export default {
        components:{
            BeforeLogin
        },
        setup() {

            let language = ref(zhCN)
            let store=useStore()
            onMounted(() => {
                store.dispatch('auth/init')
                let {lang, locale} = getLocale()
                switch (locale) {
                    case lang.zh:
                        language.value = zhCN
                        break;
                    case lang.en:
                        language.value = enUS
                        break;
                }
            })
            return {
                language,
            }
        }
    };
</script>
<style lang="scss">
#app{
    width: 100vw;
    min-width: 1060px;
    height: 100vh;
    overflow: hidden;
    background: #ffffff;
}
</style>

import constName from '../utils/constName'
let route=constName.route
export default [
    {
        path:'/',
        redirect:'/home/redirect-list'
    },
    {
        path: '/home',
        name: 'afterLayout',
        meta:{
            layout:'LoggedIn',
        },
        component: () => import( '../layout/AfterLogin'),
        children:[

            {
                path:'device-list',
                name:route.deviceList,
                meta:{
                    auth:true,
                    menu:'deviceList'
                },
                component:()=>import('../views/device/list/index')
            },
            {
                path:'redirect-list',
                name:route.redirect,
                meta:{
                    auth:true,
                    menu:'redirect'
                },
                component:()=>import('../views/device/redirect/list/index')
            },
            {
                path:'redirect-add',
                name:route.redirectAdd,
                meta:{
                    auth:true,
                    root:'redirect',
                    menu:'redirectAdd',
                    action:'redirect.add'
                },
                component:()=>import('../views/device/redirect/addUpdate')
            },
            {
                path:'redirect-edit',
                name:route.redirectEdit,
                meta:{
                    auth:true,
                    root:'redirect',
                    menu:'redirectEdit',
                    action:'redirect.edit'
                },
                component:()=>import('../views/device/redirect/addUpdate')
            },
            {
                path:'user-list',
                name:route.userList,
                meta:{
                    auth:true,
                    menu:'userList'
                },
                component:()=>import('../views/user/list/index')
            },
            {
                path:'personal',
                name:route.personSet,
                meta:{
                    auth:true,
                    menu:'personSet'
                },
                component:()=>import('../views/user/person/index')
            },
            {
                path:'system-log',
                name:route.systemLog,
                meta:{
                    auth:true,
                    menu:'systemLog'
                },
                component:()=>import('../views/system/log/index')
            },
            {
                path: '/:catchAll(.*)',
                component:() => import('../views/404.vue'),
            },
        ]

    },
    {
        path: '/forget',
        name: route.forget,
        meta: {
            layout: 'Logout',
        },
        component: () => import( '../views/forgetPwd/index'),
    },
    {
        path: '/login',
        name: route.login,
        meta: {
            layout: 'Logout',
        },
        component: () => import( '../views/login/index.vue')
    },
    {
        path: '/:catchAll(.*)',
        name:404,
        meta: {
            layout: 'Logout',
        },
        component:() => import('../views/404.vue'),
    },
]
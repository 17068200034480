import { createStore } from 'vuex'
import auth from './auth'
import appTabs from './appTabs'
import user from './user'
import log from './log'
import device from './device'
import redirect from './redirect'
export default createStore({
  modules: {
    auth,
    appTabs,
    user,
    log,
    device,
    redirect,
  }
})

export const localStore={
    get(key){
        try {
            return JSON.parse(window.localStorage.getItem(key))
        }catch (e) {
            return window.localStorage.getItem(key)
        }
    },
    set(key,val){
        try {
            window.localStorage.setItem(key,JSON.stringify(val))
        }catch (e) {
            window.localStorage.setItem(key,val)
        }
    },
    remove(key){
        return window.localStorage.removeItem(key)
    },
    clear(){
        window.localStorage.clear()
    }
}

export function iota(num=-1) {
    return function () {
        num+=1
        return num
    }
}

export function MyEvent() {
    let event={}
    return {
        on(name,body){
            if(typeof body !=='function')return //new Error('创建监听事件失败')
            event[name]=body
        },
        emit(name,params){
            let t=setTimeout(()=>{
                if(event[name]){
                    event[name](params)
                }
                clearTimeout(t)
            },100)
        },

    }
}

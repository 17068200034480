import axios from "axios";
import {message,Modal} from 'ant-design-vue'
import i18n from '../locale/main'
import {localStore} from './main'
import constName from './constName'
import checkToken from './checkToken'
import qs from 'qs'
let loginErr
const instance = axios.create({
    baseURL:process.env.VUE_APP_AxiosBaseURL,
    //baseURL:process.env.VUE_APP_URL+process.env.VUE_APP_AxiosBaseURL,
    timeout: 2*60*1000,
});

instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, async function (error) {
    if(!error.response.data.error){
        message.error({content:i18n.global.t("err.INTERNAL_SERVER_ERROR"),key:'err'})
        // 对响应错误做点什么
        return Promise.reject(error);
    }
    let err=error.response.data.error
    console.log(err)
    if(['USER_TOKEN_IS_EXPIRED','TOKEN_COMPARE_FAILED','TOKEN_IS_ILLEGAL'].indexOf(err)>-1){
        if(!loginErr){
            loginErr=true
            Modal.warning({
                title: i18n.global.t('err.loginErr'),
                content: i18n.global.t("err."+err),
                onOk(){
                    localStore.clear()
                    window.location.href='/'
                    loginErr=undefined
                }
            });
        }
    }else{
        message.error({content:i18n.global.t("err."+err),key:'err'})
    }


    // 对响应错误做点什么
    return Promise.reject(error);
});



export default async function ({auth=true,type='FORM',method,api,params,data,header={}}) {
    try {
        let headers={...header}
        let reqData=qs.stringify(data)
        if(auth){
            await checkToken()
            let loginInfo=localStore.get(constName.LoginInfo)
            headers['Authorization']=loginInfo.tokenType+' '+loginInfo.token
        }
        if(type==='json'){
            reqData=data
        }
        if(type==='formData'){
            let formData=new FormData();
            headers["content-type"]='multipart/form-data'

            let d={...data}

            for(let i in d){
                formData.append(i,d[i])
            }
            reqData=formData

        }
        return instance.request({
            headers,
            method,
            url:api,
            params,
            data:reqData,
        })
    }catch (e) {
        return Promise.reject(e)
    }
}
import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './locale/main'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import "./assets/scss/main.scss"
import {MyEvent} from "./utils/main";

let app = createApp(App)

app.use(store)
app.use(router)
app.use(i18n)
app.use(Antd)
app.mount('#app')

let t=setTimeout(()=>{
    document.title=i18n.global.t('header.name')
    clearTimeout(t)
},30)

window.myEvent=new MyEvent()
<template>
    <div class="flex right locale">
        <GlobalOutlined />
        <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
                <span v-if="locale==='zh_CN'">简体中文</span>
                <span v-if="locale==='en_US'">English</span>
                <DownOutlined/>
            </a>
            <template #overlay>
                <a-menu @click="change">
                    <a-menu-item key="zh_CN" v-if="locale==='en_US'">
                        简体中文
                    </a-menu-item>
                    <a-menu-item key="en_US" v-if="locale==='zh_CN'">
                        English
                    </a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
    </div>
</template>

<script>
    import {getLocale, setLocale} from '../locale/main'
    import {GlobalOutlined, DownOutlined} from '@ant-design/icons-vue'

    export default {
        components: {
            GlobalOutlined,
            DownOutlined,
        },
        setup() {
            let {locale} = getLocale()
            return {
                locale,
                change({key}) {
                    setLocale(key)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .locale {
        font-size: 14px;

        .anticon {
            margin-right: 8px;
        }
    }
</style>